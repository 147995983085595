// extracted by mini-css-extract-plugin
export var Footer = "reader-module--Footer--04118";
export var FooterRise = "reader-module--FooterRise--56fb0";
export var InnerFooter = "reader-module--InnerFooter--5ed34";
export var Main = "reader-module--Main--e38d7";
export var Navigation = "reader-module--Navigation--ed68e";
export var Next = "reader-module--Next--6d1ef";
export var NextArrow = "reader-module--NextArrow--45ca4";
export var PaddingBottom = "reader-module--PaddingBottom--ca96a";
export var Prev = "reader-module--Prev--40e75";
export var PrevArrow = "reader-module--PrevArrow--f061f";